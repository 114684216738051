import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { RichText, Elements } from "prismic-reactjs";
import { graphql, Link } from "gatsby";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import About from "components/About";
import Layout from "components/Layout";
import PostListItem from "components/PostListItem";
import LinkListItem from "components/LinkListItem";
import Newsletter from "components/newsletter";
import ProjectListItem from "components/ProjectListItem";


const linkResolver = (doc) => {
// Pretty URLs for known types
if (doc.type === 'post') return `/articles/${doc.uid}`
if (doc.type === 'project') return `/projects/${doc.uid}`
if (doc.type === 'page') return `/page/${doc.uid}`
// Fallback for other types, in case new custom types get created
return `/doc/${doc.id}`
}

// -- Function to add unique key to props
const propsWithUniqueKey = function(props, key) {
  return Object.assign(props || {}, { key });
};

// -- HTML Serializer
// This function will be used to change the way the HTML is loaded
const htmlSerializer = function(type, element, content, children, key) {

  var props = {};
  switch(type) {

    // Add a class to paragraph elements
    // case Elements.paragraph:
    //   props = {className: 'paragraph-class'};
    //   return React.createElement('p', propsWithUniqueKey(props, key), children);
    //
    // Don't wrap images in a <p> taglinkResolver
    // case Elements.image:
    //   props = { src: element.url , alt: element.alt || '' };
    //   return React.createElement('img', propsWithUniqueKey(props, key));
    //
    // Add a class to hyperlinks
    case Elements.hyperlink:
      const targetAttr = element.data.target ? { target: element.data.target } : {};
      const relAttr = element.data.target ? { rel: 'noopener' } : {};
      props = Object.assign({
        className: 'link-class',
        rel: element.data.url ? 'noopener noreferrer' : '',
        target: element.data.url ? 'blank' : '',
        href: element.data.url || linkResolver(element.data)
      }, targetAttr, relAttr);
      return React.createElement('a', propsWithUniqueKey(props, key), children);

    // Return null to stick with the default behavior
    default:
      return null;
  }
}




{/*import '../css/convertKit.css';*/}


const Hero = styled("div")`
    padding-top: 0.5em;
    padding-bottom: 3em;
    margin-bottom: 6em;
    max-width: 100%;

    @media(max-width:${dimensions.maxwidthMobile}px) {
       margin-bottom: 3em;
    }

    h1 {
        margin-bottom: 1em;

        a {
            text-decoration: none;
            transition: all 100ms ease-in-out;

            &:nth-of-type(1) { color: ${colors.blue500}; }
            &:nth-of-type(2) { color: ${colors.orange500}; }
            &:nth-of-type(3) { color: ${colors.purple500}; }
            &:nth-of-type(4) { color: ${colors.green500}; }
            &:nth-of-type(5) { color: ${colors.teal500}; }

            &:hover {
                cursor: pointer;
                transition: all 100ms ease-in-out;

                &:nth-of-type(1) { color: ${colors.blue600};    background-color: ${colors.blue200};}
                &:nth-of-type(2) { color: ${colors.orange600};  background-color: ${colors.orange200};}
                &:nth-of-type(3) { color: ${colors.purple600};  background-color: ${colors.purple200};}
                &:nth-of-type(4) { color: ${colors.green600};   background-color: ${colors.green200};}
                &:nth-of-type(5) { color: ${colors.teal600};    background-color: ${colors.teal200};}

            }
        }
    }
`

const Section = styled("div")`
    margin-bottom: 3em;
    display: flex;
    flex-direction: column;

    @media(max-width:${dimensions.maxwidthTablet}px) {
        margin-bottom: 4em;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
    `

    const ContentContainer = styled("div")`
    display: flex;
    flex-direction: row;

    @media(max-width:${dimensions.maxwidthTablet}px) {
      display: block;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
    `



const FullLine = styled("div")`
    display: flex;
    flex-direction: column;
    width:100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;

    @media(max-width:${dimensions.maxwidthTablet}px) {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
`



const WorkAction = styled(Link)`
    font-weight: 600;
    text-decoration: none;
    color: currentColor;
    transition: all 150ms ease-in-out;
    margin-left: auto;

    @media(max-width:${dimensions.maxwidthTablet}px) {
       margin: 0 auto;
    }

    span {
        margin-left: 1em;
        transform: translateX(-8px);
        display: inline-block;
        transition: transform 400ms ease-in-out;
    }

    &:hover {
        color: ${colors.blue500};
        transition: all 150ms ease-in-out;

        span {
            transform: translateX(0px);
            opacity: 1;
            transition: transform 150ms ease-in-out;
        }
    }
`


const ProjectItemRow = styled('div')`
  display:flex;
  width:100%;
  flex:1;
  flex-wrap:wrap;
    `

const RenderBody = ({ home, projects, combined, posts, meta, links }) =>{


  function just_tags(arr) {
    var tag_list = [];
    arr.forEach((item, i) => {
      if (typeof(item.node._meta.tags)!=='undefined') {
        item.node._meta.tags.forEach((tag, i) => {
          if (!tag_list.includes(tag)) {
            tag_list.push(tag);
          }

        },tag_list);

      }
    },tag_list);
    return tag_list;
  }


  links=links.filter(item => item['node']['link_author']==='Koby Ofek');
  var posts_and_links=posts.concat(links);
  posts_and_links=posts_and_links.sort((a, b) => (a['node']['post_date'] > b['node']['post_date']) ? -1 : 1)
  var tags = just_tags(posts_and_links);

  return (
    <>
        <Helmet>
            <title>{meta.title}</title>
            <meta name="description" content={meta.description} />
            <meta name="og:title" content={meta.title} />
            <meta name="og:description" content={meta.description} />
            <meta name="og:type" content='website' />
            <meta name="twitter:card" content='summary' />
            <meta name="twitter:creator" content='@kobyof' />
            <meta name="twitter:title" content={meta.title} />
            <meta name="twitter:description" content={meta.description} />
        </Helmet>
        <Hero>
            <>
                  <RichText render={home.hero_title} htmlSerializer={htmlSerializer} />
            </>
            {/*<a href={home.hero_button_link.url}
               target="_blank" rel="noopener noreferrer">
                <Button>
                    {RichText.render(home.hero_button_text)}
                </Button>
            </a>*/}
        </Hero>
        <Section>
          {/*<h2>Writings, Musings, Projects. All Mixed</h2>*/}
          <FullLine>
          <h2 style={{fontWeight:100}}>Latest Projects</h2>
          <ProjectItemRow>
          {projects.map((project, i) => (
            <ProjectListItem
            key={i}
            category={project.node.project_category}
            title={project.node.project_title}
            description={project.node.project_preview_description}
            thumbnail={project.node.project_preview_thumbnail}
            uid={project.node._meta.uid}
            />))}
            </ProjectItemRow>
            <WorkAction to={"/projects"}>
                See more projects <span>&#8594;</span>
            </WorkAction>
          </FullLine>
          <ContentContainer>




            <FullLine>
              <h2 style={{fontWeight:100}}>Recent Writings</h2>
              {posts_and_links.map((postOrLink, i) => {

                if (typeof(postOrLink.node.link)!=='undefined') {
                  // this means it's a link

                  return (
                    <LinkListItem
                      source='homepage'
                      title={postOrLink.node.link_title}
                      uid={postOrLink.node._meta.uid}
                      tags={postOrLink.node._meta.tags}
                      alltags={tags}
                      link={postOrLink.node.link.url}
                      description={postOrLink.node.post_preview_description}
                      key={i}
                    />
                  )
              } else {

                return (
                <PostListItem
                  source='homepage'
                  title={postOrLink.node.post_title}
                  uid={postOrLink.node._meta.uid}
                  tags={postOrLink.node._meta.tags}
                  description={postOrLink.node.post_preview_description}
                  alltags={tags}
                  key={i}
                />
              )



                }

              })}
              <WorkAction to={"/articles"}>
                  See more articles <span>&#8594;</span>
              </WorkAction>
            </FullLine>

          </ContentContainer>

        </Section>
        {/*<Section>
            {projects.map((project, i) => (
                <ProjectCard
                    key={i}
                    category={project.node.project_category}
                    title={project.node.project_title}
                    description={project.node.project_preview_description}
                    thumbnail={project.node.project_preview_thumbnail}
                    uid={project.node._meta.uid}
                />
            ))}
            <WorkAction to={"/work"}>
                See more work <span>&#8594;</span>
            </WorkAction>
        </Section>*/}
        <Section>
          <Newsletter />
        </Section>
        <Section>
            <About
                bio={home.about_bio}
                socialLinks={home.about_links}
            />
        </Section>
        <Section>
        {/*
        <script src="https://f.convertkit.com/ckjs/ck.5.js"></script>
              <form action="https://app.convertkit.com/forms/1434598/subscriptions"
              className="seva-form formkit-form"
              method="post" data-sv-form="1434598" data-uid="9a61691514" data-format="inline"
              data-version="5"
              data-options="{&quot;settings&quot;:{&quot;after_subscribe&quot;:{&quot;action&quot;:&quot;message&quot;,&quot;success_message&quot;:&quot;Success! Now check your email to confirm your subscription.&quot;,&quot;redirect_url&quot;:&quot;&quot;},&quot;analytics&quot;:{&quot;google&quot;:null,&quot;facebook&quot;:null,&quot;segment&quot;:null,&quot;pinterest&quot;:null},&quot;modal&quot;:{&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;powered_by&quot;:{&quot;show&quot;:true,&quot;url&quot;:&quot;https://convertkit.com?utm_source=dynamic&amp;utm_medium=referral&amp;utm_campaign=poweredby&amp;utm_content=form&quot;},&quot;recaptcha&quot;:{&quot;enabled&quot;:false},&quot;return_visitor&quot;:{&quot;action&quot;:&quot;show&quot;,&quot;custom_content&quot;:&quot;&quot;},&quot;slide_in&quot;:{&quot;display_in&quot;:&quot;bottom_right&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;sticky_bar&quot;:{&quot;display_in&quot;:&quot;top&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15}},&quot;version&quot;:&quot;5&quot;}" min-width="400 500 600 700 800" style={{backgroundColor: 'rgb(249, 250, 251)', borderRadius: '4px'}}><div className="formkit-background" style={{opacity: 0.2}}></div><div data-style="minimal"><div className="formkit-header" data-element="header" style={{color: 'rgb(77, 77, 77)', fontSize: '27px', fontWeight: 700}}>
                <h1>Let's talk about making awesome products, about productivity, about making the most out of life.</h1>
              </div>
              <div className="formkit-subheader" data-element="subheader" style={{color: 'rgb(104, 104, 104)', fontSize: '18px'}}>
                <p>I send some articles and musings to people. Many people find them interesting and thought provoking. Give it a shot, you probably won't regret, and in case you do - hit the unsubscribe button.</p>
              </div>
              <ul className="formkit-alert formkit-alert-error" data-element="errors" data-group="alert"></ul>
              <div data-element="fields" data-stacked="false" className="seva-fields formkit-fields">
              <div className="formkit-field">
                <input className="formkit-input" name="email_address" placeholder="Your email address" required="" type="email" style={{color: 'rgb(0, 0, 0)', borderColor: 'rgb(227, 227, 227)', borderRadius: '4px', fontWeight: 400}} /></div><button data-element="submit" className="formkit-submit formkit-submit" style={{color: 'rgb(255, 255, 255)', backgroundColor: 'rgb(22, 119, 190)', borderRadius: '4px', fontWeight: 400}}><div className="formkit-spinner"><div></div><div></div><div></div></div><span>Subscribe</span></button></div><div className="formkit-guarantee" data-element="guarantee" style={{color: 'rgb(77, 77, 77)', fontSize: '13px', fontWeight: 400}}>We won't send you spam. Unsubscribe at any time.</div>
                </div></form>
                */}

        </Section>

    </>
);
}



export default ({ data }) => {




    //Required check for no data being returned
    const doc = data.prismic.allHomepages.edges.slice(0, 1).pop();
    const projects = data.prismic.allProjects.edges;
    const posts = data.prismic.allPosts.edges;
    const meta = data.site.siteMetadata;
    const links = data.prismic.allLinks.edges;


    const forConsole = String.raw`
       wWWWw               wWWWw
 vVVVv (___) wWWWw         (___)  vVVVv
 (___)  ~Y~  (___)  vVVVv   ~Y~   (___)
  ~Y~   \|    ~Y~   (___)    |/    ~Y~
  \|   \ |/   \| /  \~Y~/   \|    \ |/
 \\|// \\|// \\|/// \\|//  \\|// \\\|///
^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
      Welcome to my digital garden.
Please take a minute to smell the flowers.`;

console.log(forConsole);








    if (!doc || !projects || !posts) return null;

    return (
        <Layout>
            <RenderBody home={doc.node} projects={projects} posts={posts} meta={meta} links={links} />
        </Layout>
    )
}

RenderBody.propTypes = {
    home: PropTypes.object.isRequired,
    projects: PropTypes.array.isRequired,
    posts: PropTypes.array.isRequired,
    meta: PropTypes.object.isRequired,
    links: PropTypes.array.isRequired
};



export const query = graphql`
    {
        prismic {
            allHomepages {
                edges {
                    node {
                        hero_title
                        hero_button_text
                        hero_button_link {
                            ... on PRISMIC__ExternalLink {
                                _linkType
                                url
                            }
                        }
                        content
                        about_title
                        about_bio
                        about_links {
                            about_link
                        }
                    }
                }
            }
            allProjects(first:4) {
                edges {
                    node {
                        project_title
                        project_preview_description
                        project_preview_thumbnail
                        project_category
                        project_post_date
                        _meta {
                            uid
                        }
                    }
                }
            }
            allLinks {
                edges {
                    node {
                      link {
                          ... on PRISMIC__ExternalLink {
                              _linkType
                              url
                          }
                       }
                       link_author
                       link_category
                       link_title
                       post_date
                       post_preview_description
                       _meta {
                         tags
                         uid
                       }
                    }
                }
            }
            allPosts {
                edges {
                    node {
                        post_title
                        post_hero_image
                        post_hero_annotation
                        post_date
                        post_category
                        post_body
                        post_author
                        post_preview_description
                        _meta {
                            uid
                            tags
                        }
                    }
                }
            }



        }
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`
