import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import colors from "styles/colors";
import PropTypes from "prop-types";

const ProjectListItemContainer = styled(Link)`
    display:flex;
    margin-bottom: 2em;
    transition: all 150ms ease-in-out;
    text-decoration: none;
    color: currentColor;
    flex:50%;
    text-align:center;
    flex-direction:column;
    padding:1%;


    @media(min-width:${dimensions.maxwidthTablet}px) {
      flex:25%;
    }




    &:hover {
        box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.1);
        transition: all 150ms ease-in-out;

        .ProjectListItemAction {
            color: ${colors.blue500};
            transition: all 150ms ease-in-out;

            span {
                transform: translateX(0px);
                opacity: 1;
                transition: transform 150ms ease-in-out;
            }
        }


    }
`

const ProjectListItemTitle = styled("h3")`
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    font-size:1.6em;
`

const ProjectListItemImageContainer = styled("div")`
    background: ${colors.grey200};
    display: block;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
    position: relative;
    border-radius:20px;
    width:100%;
    padding:0.5em;


    @media(max-width:${dimensions.maxwidthTablet}px) {
        /* padding-top: 3em; */
        /* max-height: 200px; */
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: ${colors.blue500};
        mix-blend-mode: multiply;
        opacity: 0;
        transition: all 150ms ease-in-out;
    }

    img {
        max-width: 400px;
        width: 100%;
        /* box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04); */

        @media(max-width:${dimensions.maxwidthTablet}px) {
            max-width: 300px;
        }
    }
`



const ProjectListItem = ({ category, title, description, thumbnail, uid}) => {
  var thetitle=title[0].text

return <ProjectListItemContainer to={`/projects/${uid}`}>
    <ProjectListItemImageContainer className="ProjectCardImageContainer">
        <img src={thumbnail.url} alt={title[0].text}/>
      </ProjectListItemImageContainer>

            <ProjectListItemTitle>
                {thetitle}
            </ProjectListItemTitle>


    </ProjectListItemContainer>
}

export default ProjectListItem;

ProjectListItem.propTypes = {
    category: PropTypes.array.isRequired,
    thumbnail: PropTypes.object.isRequired,
    title: PropTypes.array.isRequired,
    description: PropTypes.array.isRequired,
    uid: PropTypes.string.isRequired
}
